import { Injectable } from '@angular/core';
import {
    MatSnackBar,MatSnackBarConfig,MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,MatSnackBarRef
} from '@angular/material/snack-bar';

@Injectable()
export class SnackBarService {

    snackBarConfig: MatSnackBarConfig;
    snackBarRef: MatSnackBarRef<any>;
    horizontalPosition: MatSnackBarHorizontalPosition = 'left';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    snackBarAutoHide = 4000;

    constructor(private readonly snackBar: MatSnackBar) { }

    success(message, config?: MatSnackBarConfig, hideDuration?: any) {
        let snackMessage: string;
        message ? snackMessage = message : snackMessage = 'Success!';
        if (config !== 'undefined') {
            this.snackBarConfig = config ? config : new MatSnackBarConfig();
            this.snackBarConfig.duration = config ? +hideDuration : +this.snackBarAutoHide;
            this.snackBarConfig.panelClass = 'success-snackbar';
        } else {
            this.snackBarConfig = new MatSnackBarConfig();
            this.snackBarConfig.duration = +this.snackBarAutoHide;
            this.snackBarConfig.panelClass = 'success-snackbar';
        }
        this.snackBarRef = this.snackBar.open(snackMessage, '✖', this.snackBarConfig);
        setTimeout(() => {
        
            let buttonBig = document.getElementsByClassName('mat-simple-snackbar-action')[0].getElementsByTagName("button")[0];
            buttonBig.innerHTML = '';
            buttonBig.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" style="vertical-align: middle;stroke: white;" viewBox="0,0,256,256" style="fill:#000000;"> <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(8.53333,8.53333)"><path d="M7,4c-0.25587,0 -0.51203,0.09747 -0.70703,0.29297l-2,2c-0.391,0.391 -0.391,1.02406 0,1.41406l7.29297,7.29297l-7.29297,7.29297c-0.391,0.391 -0.391,1.02406 0,1.41406l2,2c0.391,0.391 1.02406,0.391 1.41406,0l7.29297,-7.29297l7.29297,7.29297c0.39,0.391 1.02406,0.391 1.41406,0l2,-2c0.391,-0.391 0.391,-1.02406 0,-1.41406l-7.29297,-7.29297l7.29297,-7.29297c0.391,-0.39 0.391,-1.02406 0,-1.41406l-2,-2c-0.391,-0.391 -1.02406,-0.391 -1.41406,0l-7.29297,7.29297l-7.29297,-7.29297c-0.1955,-0.1955 -0.45116,-0.29297 -0.70703,-0.29297z"></path></g></g> </svg>';
            document.getElementsByClassName('mat-simple-snackbar-action')[0].getElementsByTagName("button")[0].setAttribute('aria-label', 'Close');
        }, 100);
    }

    error(error, config?: MatSnackBarConfig, hideDuration?: any) {
        let snackError: string;
        error ? snackError = error : snackError = 'Server side error!';
        if (config !== 'undefined') {

            this.snackBarConfig = config ? config : new MatSnackBarConfig();
            this.snackBarConfig.duration = config ? +hideDuration : +this.snackBarAutoHide;
            this.snackBarConfig.panelClass = 'error-snackbar';
        } else {
            this.snackBarConfig = new MatSnackBarConfig();
            this.snackBarConfig.duration = +this.snackBarAutoHide;
            this.snackBarConfig.panelClass = 'error-snackbar';
        }
        this.snackBarRef = this.snackBar.open(snackError, '✖', this.snackBarConfig);
        setTimeout(() => {
        
            let buttonBig = document.getElementsByClassName('mat-simple-snackbar-action')[0].getElementsByTagName("button")[0];
            buttonBig.innerHTML = '';
            buttonBig.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" style="vertical-align: middle;stroke: white;" viewBox="0,0,256,256" style="fill:#000000;"> <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(8.53333,8.53333)"><path d="M7,4c-0.25587,0 -0.51203,0.09747 -0.70703,0.29297l-2,2c-0.391,0.391 -0.391,1.02406 0,1.41406l7.29297,7.29297l-7.29297,7.29297c-0.391,0.391 -0.391,1.02406 0,1.41406l2,2c0.391,0.391 1.02406,0.391 1.41406,0l7.29297,-7.29297l7.29297,7.29297c0.39,0.391 1.02406,0.391 1.41406,0l2,-2c0.391,-0.391 0.391,-1.02406 0,-1.41406l-7.29297,-7.29297l7.29297,-7.29297c0.391,-0.39 0.391,-1.02406 0,-1.41406l-2,-2c-0.391,-0.391 -1.02406,-0.391 -1.41406,0l-7.29297,7.29297l-7.29297,-7.29297c-0.1955,-0.1955 -0.45116,-0.29297 -0.70703,-0.29297z"></path></g></g> </svg>';
            document.getElementsByClassName('mat-simple-snackbar-action')[0].getElementsByTagName("button")[0].setAttribute('aria-label', 'Close');
        }, 100);
    }
    
    showCustomSnackBar(message,action,config?: MatSnackBarConfig){
        this.snackBar.open(message, action, config);
    }
}